/* Module: S */
.lhs__menu {
    position: relative;
}

.accordion {
    position: relative;
    @include drop-shadow();
}

.tabs {
    @include drop-shadow(.1);
    display: inherit;
    margin-bottom: 3rem;
}

.tab__content {
    padding: pxToRem(30) pxToRem(19);
}

.mobile-tabs__accordion {
    display: none;
}

.toc__box {
    width: $toc-box__max-width;
    display: inline-block;
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.toc__list {
    display: block;
}

.toc__link {
    width: 100%;
    max-width: $toc-box__max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rhs__column {
    margin-top: 3.5rem;
    margin-bottom: .75rem;
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-justify-content(space-between);
    // @include sq-flex-basis(100%);

    .is-listing + & {
        display: block;
    }
}

.rhs__panel {
    width: calc(50% - .75rem);
}

// Safari have some problems with proper flex wrapping
@media screen and (max-width: 63.9375em) {
    /* stylelint-disable */
    _::-webkit-full-page-media, _:future, :root .rhs__column {
        @include sq-flex-basis(auto);
    }

    _::-webkit-full-page-media, _:future, :root .main__content {
        @include sq-flex-basis(auto);
    }

    _::-webkit-full-page-media, _:future, :root .is-listing + .rhs__column {
        @include sq-flex-basis(auto);
    }
    /* stylelint-enable */
}



.form-submissions {
    &__control {
        width: 32%;
        float: left;
        margin-right: 2%;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &:after {
        content: "";
        clear: both;
        float: none;
        display: block;
    }
}
