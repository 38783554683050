/* Module: S */
.member-services, .landing__child-pages {
    padding-left: 2rem;
    padding-right: 2rem;
    &__headline {
        font-size: pxToRem(38);
        padding: pxToRem(23) 0  pxToRem(23);
    }
    .feature-box__container {
        margin-top: 0; 
        margin-bottom: pxToRem(22);
    }
}

.has-rhs-panel {
    .member-services, .landing__child-pages {
        .feature-box {
            &__container {
                width: calc(100% / 2 - 10px);
                max-height: 165px;
                height: 165px;
            }
            &__link {
                height: 165px;
                max-height: 165px;
            }
            &__heading {
                font-size: pxToRem(28);
            }
        }
    }
}