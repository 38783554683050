/* Module: S */
.quicklinks {
    position: relative;
    z-index: 16;

    &__wrapper {
        z-index: 15;
    }
}

.quicklinks__heading {
    

    // &::after {
    //     content: '';
    //     position: absolute;
    //     right: 1.25rem;
    //     top: 50%;
    //     margin-top: -8px;
    //     display: block;
    //     height: 10px;
    //     width: 10px;
    //     border-right: 2px solid #fff;
    //     border-bottom: 2px solid #fff;
    //     @include sq-transform(rotate(45deg));
    //     @include sq-transition(.15s transform linear);
    // }

   
}
.quicklinks__heading {
    // cursor: pointer;
    // position: relative;
    // top: 0;
    // left: 0;
    // width: 100%;
    display: block;
    font-size: pxToRem(18);
    font-weight: bold;
    text-align: left;
    // padding: .25rem 2.75rem .25rem 1rem;
    // padding-right: 2.75rem;
    background-color: $mav__yellow;
    color: $mav__black;
    padding: pxToRem(16) pxToRem(28);
    margin: 0;

    &::after {
        // content: '';
        // position: absolute;
        // right: 1.25rem;
        // top: 50%;
        // margin-top: -8px;
        // display: block;
        // height: 10px;
        // width: 10px;
        // border-right: 2px solid #fff;
        // border-bottom: 2px solid #fff;
        // @include sq-transform(rotate(45deg));
        // @include sq-transition(.15s transform linear);
        // content: '';
        // position: absolute;
        // right: 1.25rem;
        // // top: 50%;
        // margin-top: pxToRem(-20);
        // display: block;
        // height: 10px;
        // width: 10px;
        // border-right: 2px solid $mav__black;
        // border-bottom: 2px solid $mav__black;
        display: none;
    }
    &[data-expanded="true"] {
        &::after {
            margin-top: pxToRem(-16);
            @include sq-transform(rotate(-135deg));
        }
    }
}

.quicklinks__content {
    display: none;
    background-color: $block-content__bg;
    padding: .75rem 1rem;
}

.quicklinks__heading.quicklinks__link {
    &:after {
        content: none;
    }
    display: block;
    text-decoration: none;
    background: $vic__grey;
    color: $mav__black;
    font-size: pxToRem(16);
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    &:hover {
        background: $vic__purple;
        color: $mav__white;
    }
    &:last-child {
        border-bottom: none;
    }
}