/* Module: S */
.quicklinks__wrapper {
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    bottom: pxToRem(-55);
    @include sq-transform(translateX(-50%));
    z-index: 15;
}

h2.quicklinks__heading {
    width: pxToRem(170);
    text-align: center;
    &:after {
        content: none;
        display: none;
    }
}

.quicklinks__container {
    display: block;
}

.quicklinks {
    position: absolute;
    bottom: 0;
    right: 2rem;
    max-width: pxToRem(250);
    width: 100%;
}
