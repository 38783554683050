/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import 'boilerplate.scss';

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import 'normalize.scss';

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import 'normalize-opentype.scss';

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

html {
    @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import 'utilities.scss';

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection__highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection__highlight;
    text-shadow: none;
}

html,
body {
    height: 100%;
}
body {
    font-size: $base__font-scale + em;
    font-family: $base__font-family;
    color: $mav--black;
    line-height: $base__line-height;
    background-color: $base__bg;
    padding: 0;
    margin: 0;
    overflow-y: scroll;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
        overflow-x: hidden;
    }
}

img {
    max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: .5cm;
}

a {
    color: $global-link__color;

    &:hover,
    &:focus {
        color: $global-link__color--hover;
        text-decoration: none;
    }
}

a[rel="external"] {
    &::after {
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gZmlsbD0iIzAwNzZhZCIgcG9pbnRzPSI5MS44NzUsNjIgODUsNjIgODUsODUgMTUsODUgMTUsMTUgMzgsMTUgMzgsOC4xMjUgMzgsMCAwLDAgMCwxMDAgMTAwLDEwMCAxMDAsNjIgIj48L3BvbHlnb24+PHBvbHlnb24gZmlsbD0iIzAwNzZhZCIgcG9pbnRzPSI1NCwwIDU0LDAuNjI3IDcxLjc4NywxOC40NzcgNTIuNzAxLDM4IDM4LDM4IDM4LDYyIDYyLDYyIDYyLDQ5Ljc5OSA4Mi43NzMsMjkuNDYyIDk5LjM3NCw0NiAxMDAsNDYgMTAwLDAgIj48L3BvbHlnb24+PC9zdmc+');
        width: 11px;
        height: 11px;
        display: inline-block;
        margin-left: 4px;
    }
}

/*
--------------------
Content
--------------------
*/

/* Buttons */
.btn,
.atcb-link {
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid transparent;
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    @include sq-transition(.2s all ease-in-out);
}

.btn-primary {
    color: $mav__blue--dark;
    background-color: $btn-primary__bg;
    border-color: $mav__blue--dark;

    &:hover,
    &:focus {
        color: $btn-primary__color--hover;
        background-color: $mav__blue--dark;
        border-color: $mav__blue--dark;
    }
    &.btn-inverted {
      color: $btn-primary__color--hover;
      background-color: $mav__blue--dark;
      border-color: $mav__blue--dark;
      &:hover,
      &:focus {
          color: $mav__blue--dark;
          background-color: $btn-primary__bg;
          border-color: $mav__blue--dark;
      }
    }
}

.sq-form-submit {
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid transparent;
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    @include sq-transition(.2s all ease-in-out);
    color: $btn-primary__color--hover;
    background-color: $btn-primary__bg--hover;
    border-color: $btn-primary__border--hover;
    &:hover,
    &:focus {
      color: $btn-primary__color;
      background-color: $btn-primary__bg;
      border-color: $btn-primary__border;
    }
}

.btn-secondary {
    color: $btn-secondary__color;
    background-color: $btn-secondary__bg;
    border-color: $btn-secondary__border;

    &:hover,
    &:focus {
        color: $btn-secondary__color--hover;
        background-color: $btn-secondary__bg--hover;
        border-color: $btn-secondary__border--hover;
    }
}

.btn-success {
    color: $btn-success__color;
    background-color: $btn-success__bg;
    border-color: $btn-success__border;

    &:hover,
    &:focus {
        color: $btn-success__color--hover;
        background-color: $btn-success__bg--hover;
        border-color: $btn-success__border--hover;
    }
}

.btn-info {
    color: $btn-info__color;
    background-color: $btn-info__bg;
    border-color: $btn-info__border;

    &:hover,
    &:focus {
        color: $btn-info__color--hover;
        background-color: $btn-info__bg--hover;
        border-color: $btn-info__border--hover;
    }
}

.btn-warning {
    color: $btn-warning__color;
    background-color: $btn-warning__bg;
    border-color: $btn-warning__border;

    &:hover,
    &:focus {
        color: $btn-warning__color--hover;
        background-color: $btn-warning__bg--hover;
        border-color: $btn-warning__border--hover;
    }
}

.btn-danger {
    color: $btn-danger__color;
    background-color: $btn-danger__bg;
    border-color: $btn-danger__border;

    &:hover,
    &:focus {
        color: $btn-danger__color--hover;
        background-color: $btn-danger__bg--hover;
        border-color: $btn-danger__border--hover;
    }
}

.btn-social,
.atcb-link {
    color: #fff;
    background-color: $vic__purple;
    border-color: $vic__purple;

    + .btn-social,
    + .addtocalendar,
    .addthis-toolbox + &,
    .addtocalendar + &,
    + .addthis-toolbox {
        margin-left: .75rem;
    }

    &:hover,
    &:focus {
        color: #fff;
        background-color:$vic__purple--bright;
        border-color: $vic__purple--bright;
    }
}

.btn-block {
    width: 100%;
    display: block;

    + .btn-block {
        margin-top: .5rem;
    }
}

.btn-small {
    font-size: .8rem;
    padding: .4rem .75rem;
}

.btn-large {
    font-size: 1.25rem;
    padding: .75rem 1.5rem;
}

.btn-filter {
    cursor: pointer;
    padding: pxToRem(7);
    margin-bottom: 0;
    position: absolute;
    right: 0;
}

/* stylelint-disable */
.addthis_button {
    position: relative;
    color: #000;
    background-color: $mav__yellow;
    border-color: $mav__yellow;

    &:after {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-left: 1rem;
        margin-top: -6px;
        vertical-align: middle;
        border-right: 3px solid $mav__blue--dark;
        border-bottom: 3px solid $mav__blue--dark;
        @include sq-transform(rotate(45deg));
    }

    &:hover,
    &:focus {
        color: #fff;
        background-color: $mav__blue--dark;
        border-color: $mav__blue--dark;
        &:after {
            border-color: #fff;
        }
    }
}
/* stylelint-enable */

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
.main__content {
    @import 'content.scss';
}

.main-content__wrapper {
    @include sq-flex-box;
    @include sq-flex-direction(column);
    width: auto;
    min-width: 1px;

    .home & {
        padding: 0;
        max-width: 100%;
        display: block;
    }
}

//Icons
.icon-image {
    height: 100%;
    width: 100%;
}

.main {
    background-color: $base__bg;
    position: relative;
    transition: .3s ease transform;
    will-change: transform;
}

.main__footer {
    position: relative;
    transition: .3s ease transform;
    will-change: transform;
}

.wrapper {
    @include sq-flex-box;
    @include sq-flex-direction(column);
    max-width: $page__max-width;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    + .wrapper {
        padding-top: 0;
    }

    .home & {
        padding: 0;
        max-width: 100%;
        display: block;
    }
}

.tool-box {
    @include sq-flex-box;
    @include sq-flex-basis(100%);
    @include sq-align-items(center);
    @include sq-justify-content(space-between);
    margin-bottom: 2rem;
}

.tools-social {
    @include sq-flex-box;
    > * {
        margin-right: 1rem;
        &:last-child(){
            margin-right: 0;
        }
    }
}

// _::-webkit-full-page-media, _:future, :root .tool-box {
//     @include sq-flex-basis(auto);
// }

.home {
    .page-heading {
        display: none;
    }
}

.space-between {
    @include sq-justify-content(space-between);
}

.compat-object-fit {
  background-position: center center;
  background-size: cover;

  img {
    opacity: 0;
  }
}


/*
--------------------
Modules
--------------------
*/
