/* Module: S */
.member-services, .landing__child-pages {
    &__headline {
        padding: pxToRem(23) 0 pxToRem(49);
    }
    .feature-box__container {
        margin-top: 0; 
    }
    .small-box .feature-box__heading {
        height: auto;
    }
    .image-box .feature-box__content {
        display: block;
        color: $mav__black;
        padding: 16px 30px;
        top: 30%;
    }
    .image-box > .feature-box__link::before{
        background: linear-gradient(to right, rgba(255,255, 255,0.8), rgba(255,255, 255,1));
    }
    .feature-box__link:hover {
        .feature-box__heading.text-light {
            color: $mav__black;
        }
    }
}

.has-rhs-panel {
    .member-services, .landing__child-pages {
        .feature-box {
            &__container {
                width: calc(100% / 2 - 10px);
                height: 165px;
                max-height: 165px;
            }
            &__link {
                height: 165px;
                max-height: 165px;
            }
            &__heading {
                font-size: pxToRem(28);
            }
        }
        .image-box > .feature-box__link:hover .feature-box__content, .image-box > .feature-box__link:focus .feature-box__content {
                -webkit-transform: translateY(-51px);
                -ms-transform: translateY(-51px);
                transform: translateY(-51px);
        }

    }
}