/* Module: S */
.social-feed {
    &__wrapper {
        max-width: pxToRem(1344);
        margin: 0 auto;
        padding: pxToRem(19) pxToRem(32) pxToRem(75);
        display: flex;
    }
    &__heading {
        padding-top: pxToRem(60);
    }
    &__item {

    	iframe {
    		width: 100%;
    	}
    }
    &__linkedin, &__facebook, &__twitter {
        & > img {
            float: left;
            margin-left: 16px;
        }
    }
}