/* Module: S */
.quicklinks__heading {
    display: inline-block;
    // padding: .375rem 1.25rem;
    z-index: 17;
}

// .quicklinks__block {
//     .quicklinks__heading {
//         position: relative;
//         padding: pxToRem(5) 2.75rem pxToRem(5) 2rem;
//     }
// }

// .quicklinks__content {
//     padding: 1rem 2rem;
// }
