/* Module: S */
.lhs__menu {
    margin-bottom: 1.5rem;
}
.lhs__menu-toggle {
    color: $lhs-menu__mobile-toggler__color;
    background-color: $lhs-menu__mobile-toggler__bg;
    position: relative;
    width: 100%;
    display: block;
    font-size: 125%;
    border: 0;
    text-align: left;
    line-height: 1.25;
    padding: 1rem 1.5rem;
    @include sq-transition(.2s all linear);

    &:focus {
        outline: 0;
    }

    &::after {
        content: '';
        position: absolute;
        top: 1.4rem;
        right: 1.65rem;
        display: block;
        width: .85rem;
        height: .85rem;
        border-right: 4px solid $lhs-menu__mobile-toggler-arrow__color;
        border-bottom: 4px solid $lhs-menu__mobile-toggler-arrow__color;
        @include sq-transition(.2s transform linear, .2s border-color linear);
        @include sq-transform(rotate(-45deg));
    }

    &[data-expanded='true'] {
        color: $lhs-menu__mobile-toggler__color--active;
        background-color: $lhs-menu__mobile-toggler__bg--active;
        &::after {
            top: 1.25rem;
            border-right-color: $lhs-menu__mobile-toggler-arrow__color--active;
            border-bottom-color: $lhs-menu__mobile-toggler-arrow__color--active;
            @include sq-transform(rotate(45deg));
        }
    }
}

.lhs__menu-list:not(.menu__level-2, .menu__level-3, .menu__level-4, .menu__level-5) {
    display: none;
    margin-bottom: 2rem;
}

.lhs__menu-list {
    @extend %list-reset;

    &.menu__level-2,
    &.menu__level-3,
    &.menu__level-4,
    &.menu__level-5 {
        border-top: 1px solid $vic__border-grey;
    }
}

.lhs__menu-item {
    border-bottom: 1px solid $vic__border-grey;

    &:last-child {
        border-bottom: 0;
    }
}

.lhs__menu-link {
    background-color: $vic__grey;
    display: block;
    position: relative;
    font-size: pxToRem(14);
    //font-weight: 600;
    text-decoration: none;
    color: $lhs-menu__level-1__color;
    padding: .75rem 1rem;
    @include sq-transition(.1s color ease-in-out, .1s background-color ease-in-out);

    &.active,
    &.current {
        font-weight: 600;
    }

    /*&.active {
        color: $lhs-menu__level-1__color--active;
        background-color: $lhs-menu__level-1__bg;
    }*/

    &.current.has-children {
        padding-right: 2.75rem;
        &::after {
            content: '';
            position: absolute;
            right: 1.15rem;
            top: .95rem;
            display: block;
            height: 10px;
            width: 10px;
            border-right: 3px solid $lhs-menu__level-1-arrow__color;
            border-bottom: 3px solid $lhs-menu__level-1-arrow__color;
            @include sq-transform(rotate(45deg));
        }
        &:hover::after {
            border-right: 3px solid $mav__white;
            border-bottom: 3px solid $mav__white;
        }
    }

    &:hover,
    &:focus {
        color: $lhs-menu__level-1__color--hover;
        background-color: $lhs-menu__level-1__bg--hover;
    }

    .menu__level-2 & {
        color: $lhs-menu__level-2__color;
        background-color: $lhs-menu__level-2__bg;
        padding-left: 1.75rem;

       /* &.active {
            color: $lhs-menu__level-2__color--active;
            background-color: $vic__purple;
        }*/

        &.current::after {
            border-right-color: $lhs-menu__level-2-arrow__color;
            border-bottom-color: $lhs-menu__level-2-arrow__color;
        }

        &.current:hover::after {
            border-right-color: $mav__white;
            border-bottom-color: $mav__white;
        }

        &:hover,
        &:focus {
            color: $lhs-menu__level-2__color--hover;
            background-color: $lhs-menu__level-2__bg--hover;
        }
    }

    .menu__level-3 & {
        color: $lhs-menu__level-3__color;
        background-color: $lhs-menu__level-3__bg;
        padding-left: 2.5rem;

        /*&.active {
            color: $lhs-menu__level-3__color--active;
            background-color: $vic__purple;
        }*/

        &.current::after {
            border-right-color: $lhs-menu__level-3-arrow__color;
            border-bottom-color: $lhs-menu__level-3-arrow__color;
        }

        &.current:hover::after {
            border-right-color: $mav__white;
            border-bottom-color: $mav__white;
        }

        &:hover,
        &:focus {
            color: $lhs-menu__level-3__color--hover;
            background-color: $lhs-menu__level-3__bg--hover;
        }
    }

    .menu__level-4 & {
        color: $lhs-menu__level-4__color;
        background-color: $lhs-menu__level-4__bg;
        padding-left: 3.25rem;

        /*&.active {
            color: $lhs-menu__level-4__color--active;
            background-color: $vic__purple;
        }*/

        &.current::after {
            border-right-color: $lhs-menu__level-4-arrow__color;
            border-bottom-color: $lhs-menu__level-4-arrow__color;
        }

        &.current:hover::after {
            border-right-color: $mav__white;
            border-bottom-color: $mav__white;
        }

        &:hover,
        &:focus {
            color: $lhs-menu__level-4__color--hover;
            background-color: $lhs-menu__level-4__bg--hover;
        }
    }

    .menu__level-5 & {
        color: $lhs-menu__level-5__color;
        background-color: $lhs-menu__level-5__bg;
        padding-left: 4rem;

        /*&.active {
            color: $lhs-menu__level-5__color--active;
            background-color: $vic__purple;
        }*/

        &.current::after {
            border-right-color: $lhs-menu__level-5-arrow__color;
            border-bottom-color: $lhs-menu__level-5-arrow__color;
        }

        &.current:hover::after {
            border-right-color: $mav__white;
            border-bottom-color: $mav__white;
        }

        &:hover,
        &:focus {
            color: $lhs-menu__level-5__color--hover;
            background-color: $lhs-menu__level-5__bg--hover;
        }
    }
}

.rhs__column {
    margin-top: $rhs-column__margin-left;

    .is-listing + & {
        margin-top: 0;
        @include sq-order(-1);
    }
}

.rhs-filters__container {
    margin-bottom: pxToRem(20);
}

.rhs__panel {
    position: relative;
    color: $rhs-panel__color;
    margin-bottom: $rhs-panel__margin-bottom;
    background-color: $rhs-panel__bg;

    &.rhs__related {
        background-color: transparent;
        box-shadow: none;
        padding: 1rem 1rem 0 1rem;

        &::after {
            content: none;
        }
    }

    .popup__image {
        position: relative;
        z-index: 2;
    }
}
.rhs__image-panel {
    display: block;
    height: 100%;
    .rhs__panel-image {
        height: 100%;
        object-fit: cover;
    }
}
.rhs__panel-heading {
    font-size: pxToRem(20);
    font-weight: 600;
    color: $vic__purple;
    background-color: $rhs-panel-heading__bg;
    padding: .75rem 1rem;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 0;

    .rhs__related & {
        background-color: transparent;
        padding: 0;
        margin-bottom: 1rem;

        &:after {
            content: '';
            display: block;
            width: 1.5rem;
            height: .25rem;
            margin-top: .75rem;
            background-color: $vic__purple;
        }
    }

    &.inside-content {
        background-color: transparent;
        border-bottom: 0;
        padding: 0;
        margin-bottom: .5rem;
    }
}

.rhs__panel-links__list {
    @extend %list-reset;
}

.rhs__panel-image {
    width: 100%;
    display: block;
}

.rhs-panel__link {
    position: relative;
    background-color: $rhs-panel-link__bg;
    color: $rhs-panel-link__color;
    display: block;
    padding: .2rem 2rem .2rem 0;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include sq-transition(.15s color ease-in-out);

    &::after {
        content: '';
        position: absolute;
        top: .7rem;
        right: .1rem;
        display: block;
        height: 6px;
        width: 6px;
        border-right: 2px solid $vic__purple;
        border-bottom: 2px solid $vic__purple;
        @include sq-transform(rotate(-45deg));
        @include sq-transition(.25s border-color linear, .25s right linear);
    }

    &:hover,
    &:focus {
        color: $rhs-panel-link__color--hover;
        background-color: $rhs-panel-link__bg--hover;

        &::after {
            border-right-color: $rhs-panel-link-arrow__color--hover;
            border-bottom-color: $rhs-panel-link-arrow__color--hover;
        }
    }

    &:last-child {
        border-bottom: 0;
    }
}

.rhs__panel-content {
    color: $rhs-panel-content__color;
    background-color: $rhs-panel__bg;
    padding: 1rem;
    line-height: 1.65;
    position: relative;
    z-index: 2;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color : $vic__purple;
    }

    > h2:first-child,
    > h3:first-child,
    > h4:first-child,
    > h5:first-child {
        margin-top: 0;
    }

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

%heading {
    position: relative;
    display: block;
    width: 100%;
    border: 0;
    outline: 0;
    padding: .65rem 1rem;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    @include sq-flex(1 1 0%);
    @include sq-transition(.15s background-color linear);
}

.accordion,
.tabs,
.mobile-tabs__accordion {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.accordion {

}

.accordion__block {
    border: solid 1px $accordion-separator__color;
}

.accordion__heading {
    background-color: $accordion-heading__bg;
    color: $mav__blue--dark;
    font-weight: bold;
    @extend %heading;
    padding: pxToRem(19);
    line-height: 1;

    &:hover,
    &:focus {
        color: $mav__white;
        background-color: $mav__blue;

        &::before,
        &::after {
            background-color: $mav__white;
        }
    }

    &::before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 3px;
        height: 13px;
        top: 20px;
        right: 25px;
        background-color: $mav__blue--dark;
    }

    &::after {
        content: '';
        position: absolute;
        right: 1.25rem;
        top: 25px;
        display: inline-block;
        width: 13px;
        height: 3px;
        background-color: $mav__blue--dark;
    }

    .active > & {
        color: $mav__blue--dark;
        background-color: $mav__white;

        &::after {
            background-color: $mav__blue--dark;
        }

        &::before {
            content: none;
        }
    }
}

.accordion__content {
    background-color: $accordion-content__bg;
    display: none;
    overflow: hidden;
    padding: pxToRem(6) pxToRem(19) pxToRem(19);

    p {
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.tabs {
    position: relative;
    display: none;
}

/* stylelint-disable */
.tab__buttons {
    @include sq-flex-box;
}
/* stylelint-enable */

.tab__button {
    color: $tabs-tab__color;
    background-color: $mav__blue--dark;
    text-align: center;
    @extend %heading;
    font-weight: bold;
    margin-right: 4px;
    border: 1px solid transparent;
    border-bottom: 0;


    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        color: $mav__white;
        background-color: $mav__blue;
    }

    &.active {
        color: $mav__white;
        background-color: $mav__blue;
        border: 1px solid $mav__blue;
        border-bottom: 0;
    }
}

.tabs__contents {
    position: relative;
    border: 1px solid $accordion-separator__color;
    border-top: 0;

}

.tab__content {
    display: none;
    padding: 1rem;
    background-color: $tabs-content__bg;

    p {
        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.active {
        display: block;
    }
}

.gallery {
    max-width: $gallery__max-width;
    margin: 0 auto;
}

.gallery__single {
    position: relative;
    z-index: 1;
}

.gallery__nav {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    margin: 0 auto 3rem;
    padding: 1.25rem 1rem;
    position: relative;
    top: -24px;
    width: 90%;
    z-index: 2;
    @include drop-shadow();
    .gallery__item {
        outline: none;
    }
    .gallery__image {
        // padding-left: .15rem;
        // padding-right: .15rem;
        cursor: pointer;
        border: 4px solid transparent;

        &:hover,
        &:focus {
            border: 4px solid $mav__blue;
        }
    }

    .slick-list {
        position: relative;
    }

    .slick-next,
    .slick-prev {
        position: absolute;
        top: 50%;
        right: -1.75rem;
        padding: 0;
        margin: pxToRem(-13) 0 0 0;
        background-color: transparent;
        color: transparent;
        text-indent: 200%;
        overflow: hidden;
        border: 0;
        height: pxToRem(28);
        width: pxToRem(22);

        &::after {
            content: '';
            position: absolute;
            top: pxToRem(8);
            left: pxToRem(6);
            height: 12px;
            width: 12px;
            border-right: 4px solid $mav__blue--dark;
            border-bottom: 4px solid $mav__blue--dark;
            @include sq-transform(rotate(-45deg));
        }
    }

    .slick-prev {
        left: -1.75rem;
        right: auto;
        &::after {
            left: auto;
            right: pxToRem(6);
            border-right: 0;
            border-left: 4px solid $mav__blue--dark;
            border-bottom: 4px solid $mav__blue--dark;
            @include sq-transform(rotate(45deg));
        }
    }
}

.gallery__image {
    display: block;
}

.gallery__description {
    color: $gallery-description__color;
    background-color: $gallery-description__bg;
    padding: .5rem .75rem;
    margin-top: .5rem;
}

.form__group, .sq-form-question {
    margin-bottom: 1rem;

    label {
        color: #393939;
    }

    .form__group:last-child,
    .form__check:last-child {
        margin-bottom: 0;
    }
}

.sq-form-question-answer {
    > ul {
        > li{
            padding-left: 0!important;
            margin-bottom: 0!important;
            &::before {
                content: none!important;
            }
        }
    }
}

.form__group-inline {
    position: relative;
    @include sq-flex-box;

    > .datepicker__wrap {
        display: block;
        width: 100%;
    }

    > input[type='text'],
    > .datepicker__wrap {
        @include sq-flex-shrink(1);
    }
}

.form-fieldset__content {
    display: none;
    margin-top: .75rem;

    li {
        input[type='checkbox'],
        input[type='radio'] {
            margin-right: .25rem;
            margin-top: -4px;
            vertical-align: middle;
        }

        &:last-child {
            label {
                margin-bottom: 0;
            }
        }
    }
}
.rhs__column .form-fieldset__content {
    display: block;
}


.form-submissions {
    &__control {
        width: 100%;

        select::-ms-expand {
            display: none;
        }
    }
    &__submit-wrapper {
        margin-top: 10px;
    }
}

.responsive__table {
    table {
        margin: 0;
    }
}

.sq-form-question-option-list li,
.sq-form-question-tickbox-list li,
.form__check {
    position: relative;
    display: block;
    margin-bottom: .25rem;
}

.sq-form-question-option-list label,
.sq-form-question-tickbox-list label,
.form-check__label {
    color: $form-check__color;
    padding-left: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    cursor: pointer;

    .disabled > & {
        color: $form-check__color--disabled;
        cursor: not-allowed;
    }
}

.sq-form-question-option-list input[type="radio"],
.sq-form-question-tickbox-list input[type="checkbox"]{
    position: absolute;
    margin-top: .25rem;
    margin-left: 0;
}

.form-check__input {
    position: absolute;
    margin-top: .25rem;
    margin-left: -1.5rem;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.form__control,
.sq-form-field:not([type="radio"]):not([type="checkbox"]) {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    color: $form-control__color;
    font-size: 1rem;
    line-height: 1.25;
    border: $form-control__border;
    background-color: $form-control__bg;
    border-radius: $form-control__border-radius;
    @include sq-transition(.2s background-color ease-in-out, .2s border-color ease-in-out, .2s color ease-in-out);

    &:focus {
        color: #464a4c;
        background-color: #fff;
        border: $form-control__border--focus;
        outline: 0;
    }

    .has-success & {
        border-color: $form-control-success__color;
    }

    .has-warning & {
        border-color: $form-control-warning__color;
    }

    .has-error & {
        border-color: $form-control-error__color;
    }

    .form__group-inline > & {
        margin-right: .5rem;

        &:last-child:not(:first-child) {
            margin-right: 0;
        }
    }
}

.rhs-filters__container {
    .form__control,
    .sq-form-field:not([type="radio"]):not([type="checkbox"]) {
        .form__group-inline > & {
            margin-right: 0;
        }
    }
}

select.form__control:not([size]):not([multiple]),
select.sq-form-field:not([size]):not([multiple]) {
    padding-right: 2rem;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiwwLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0ICAgQzEuODA3LDcyLjk5OCwwLDc3LjI3OSwwLDgyLjIyOGMwLDQuOTQ4LDEuODA3LDkuMjI5LDUuNDI0LDEyLjg0N2wxMjcuOTA3LDEyNy45MDdjMy42MjEsMy42MTcsNy45MDIsNS40MjgsMTIuODUsNS40MjggICBzOS4yMzMtMS44MTEsMTIuODQ3LTUuNDI4TDI4Ni45MzUsOTUuMDc0YzMuNjEzLTMuNjE3LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N0MyOTIuMzYyLDc3LjI3OSwyOTAuNTQ4LDcyLjk5OCwyODYuOTM1LDY5LjM3N3oiIGZpbGw9IiMwMDAwMDAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 12px;
}

.form__control-success,
.form__control-warning,
.form__control-error {
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right .5625rem;
    background-size: 1.125rem 1.125rem;
}

/* stylelint-disable */
.form__control-success {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-control-success__color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E"), "#", "%23");
}

.form__control-warning {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-control-warning__color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"), "#", "%23");
}

.form__control-error {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-control-error__color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='#{$form-control-error__color}' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"), "#", "%23");
}
/* stylelint-enable */

.form-control__feedback {
    margin-top: .3rem;
    font-weight: 400;

    .has-success & {
        color: $form-control-success__color;
    }

    .has-warning & {
        color: $form-control-warning__color;
    }

    .has-error & {
        color: $form-control-error__color;
    }
}

.sq-form-question-error {
    .form__control {
        border-color: $form-control-error__color;
    }
    .sq-form-error {
        color: $form-control-error__color;
        margin-top: .5rem;
    }
}

.form__text {
    display: block;
    margin-top: .25rem;
    font-size: $form-text__font-size;
    color: $form-text__color;
}

.form__list {
    @extend %list-reset;
}

.form-list__item {
    @extend %clearfix;
    // margin-bottom: .4rem;
    padding-bottom: .4rem;
    // border-bottom: 1px solid darken(#fff, 20%);

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.form-list__link {
    @include sq-transition(.15s color ease-in-out);
    color: $form-links__color;
    text-decoration: none;

    &:hover,
    &:focus,
    &.active {
        color: $form-links__color--hover;
    }

    &.active {
        font-weight: 600;
    }
}

.form-list__count {
    float: right;
    font-size: 90%;
    font-family: Helvetica, Arial, Sans-serif;
    line-height: 1em;
    color: $color__quaternary;
    background-color: darken(#fff, 15%);
    padding: .25rem .35rem;
    border-radius: .25rem;
}

.form__separator {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-top-color: $form-separator__color;
    border-bottom: 0;
}

.toc__list {
    @extend %list-reset;
    display: none;
}

.toc__toggler {
    font-size: 1rem;
    color: #58595b;
    background-color: transparent;
    font-weight: 600;
    border: 0;
    padding: 0;
    margin: 0;
    float: right;
}

h3.toc__heading {
    color: $mav__blue--dark;
    font-size: pxToRem(20);
    font-weight: 600;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 1rem;

    &::after {
        content: '';
        display: block;
        width: 1.5rem;
        height: .25rem;
        margin-top: .65rem;
        background-color: $mav__blue--dark;
    }
}

.toc__link {
    position: relative;
    display: block;
    font-size: pxToRem(15);
    color: $toc-link__color;
    text-decoration: none;
    padding-right: 1.5rem;

    @include sq-transition(.15s color ease-in-out);

    &::after {
        content: '';
        position: absolute;
        top: .4rem;
        right: .1rem;
        display: block;
        height: 6px;
        width: 6px;
        border-right: 2px solid $mav__blue--dark;
        border-bottom: 2px solid $mav__blue--dark;
        @include sq-transform(rotate(-45deg));
    }

    &:hover,
    &:focus {
        color: $mav__blue--dark;
    }
}

.toc__item {
    margin-bottom: .5rem;

    &:last-child {
        margin-bottom: 0;
    }

    // Yes, IE is retarded and doesn't respect :not()
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding-left: 0 !important;
        margin-left: 0 !important;

        &:before {
            content: none !important;
        }
    }
}

/* stylelint-disable */
.gMap {
    width: 100%;
    height: 400px;
}
/* stylelint-enable */
.btt__wrapper {
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 0 1.5rem;
}
.btt {
    max-width: 80rem;
    margin: 0 auto;
}

.btt a {
    float: right;
    display: block;
    height: 36px;
    width: 80px;
    position: relative;
    background-color: $vic__purple;
    border: 2px solid $vic__purple;
    transition: all .3s ease;
    color: #fff;
    &::before {
        content: '';
        position: absolute;
        border-width: 3px 0 0 3px;
        border-style: solid;
        border-color: #fff;
        width: 12px;
        height: 12px;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: all .3s ease;
    }
    &:hover,
    &:focus {
        background: #fff;
        &::before {
            border-color: $vic__purple;
        }
    }
}

.figure__image {
    position: relative;
    margin-bottom: 2.5rem;

    img {
        display: block;
    }

    figcaption {
        color: #2e2e2e;
        margin-top: .5rem;
        font-size: pxToRem(14);
    }
}

.popup__video {
    text-decoration: none;
}

.addtocalendar {
    position: relative;
    display: inline-block;
    background: transparent;

    var {
        display: none;
    }

    .atcb-list {
        @extend %list-reset;
        position: absolute;
        left: 0;
        display: none;
        background-color: #fff;
        margin-top: .25rem;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: .25rem;
        box-shadow: 0 1px 8px rgba(0, 0, 0, .1);

        &:hover {
            display: block;
        }
    }

    .atcb-link:focus,
    .atcb-link:active {
        + .atcb-list {
            display: block;
        }
    }

    .atcb-item-link {
        font-size: pxToRem(15);
        padding: .35rem .75rem;
        display: block;
        text-decoration: none;
        white-space: nowrap;

        &:hover,
        &:focus {
            background-color: darken(#fff, 6%);
        }
    }
}

.at-share-tbx-element .at-icon-wrapper {
    display: none !important;
}
.video-container {
    height: 0;
    margin-bottom: 1rem;
    overflow: hidden;
    padding-bottom: 56.25%; // Video area
    padding-top: pxToRem(30); // 30px controls container
    position: relative;
}

.video-container embed,
.video-container iframe,
.video-container object {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.video-poster a {
    img {
        vertical-align: bottom;
        width: 100%;
        // @include sq-transform(scale(1));
        // @include sq-transition(.3s transform linear);
        // -webkit-backface-visibility: hidden;
        // backface-visibility: hidden;
    }
    .image__wrapper {
        position: relative;
        overflow: hidden;
        &::before {
            content: '';
            width:100%;
            height: 100%;
            background-color: #262626;
            opacity: .6;
            position: absolute;
        }
        &::after {
            content: '';
            top: calc(50% - 25px);
            right: calc(50% - 25px);
            position: absolute;
            background-image: url('data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgMzU3IDM1NyI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTM4LjI1IDB2MzU3bDI4MC41LTE3OC41eiIvPjwvc3ZnPg==');
            background-repeat: no-repeat;
            background-size: 50px;
            background-position: center center;
            height: 50px;
            width: 50px;
        }
    }
    &:hover img {
        // @include sq-transform(scale3d(1.2,1.2,1));
    }
}
.map__description {
    position: relative;
    padding: pxToRem(31);
    background-color: $home-box__bg;
    @include drop-shadow();
    color: #2e2e2e;
    h3.map__description-heading {
        color: $home-box-heading__color;
        margin-top: 0;
        margin-bottom: pxToRem(21);
        font-size: pxToRem(22);
    }
    p {
        margin: 0;
    }
}


.page_account_manager {
    .error-info {
        color: red;
    }
}

.register-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
