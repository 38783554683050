/* Module: S */
.social-feed {
    &__wrapper {
        display: block;
        padding: 0 pxToRem(32) pxToRem(40);
        justify-content: space-between;
    }
    &__item {
        width: 100%;
        margin: 0 auto;
    }
}