/* Module: S */
.landscape-carousel__image {
    height: pxToRem(500);
}

.landscape-carousel__controls {
    bottom: 2rem;
    padding-left: 21rem;
}

.landscape-carousel__caption {
    // width: auto;
    width: pxToRem(512);
    padding: 2rem;
}

// .landscape-carousel__caption-heading {
//     font-size: pxToRem(50);
//     line-height: 1.28;
// }
