/* Module: S */
.section__map-pannels {
	.content-wrapper {
		display: flex;
		max-width: 84rem;
		width: 100%;
		margin: 0 auto;
	}
	.map-pannel {
		flex: 1;
		&:nth-child(odd) {
			margin-right: 15px;
			margin-bottom: 0;
		}
		&:nth-child(even) {
			margin-left: 15px;
		}
	}
}