/* Module: S */
.search-result__image-wrapper {
    height: 379px;
    width: 244px;

    + .search-result__content {
        padding-left: calc(244px + 29px);
        height: 379px;
    }
}

.search-result__image {
    max-width: 244px;
}

.listing-3-col {
    .search-result__image-wrapper {
        display: inherit;

        + .search-result__content {
            padding-left: calc(244px + 29px);
        }
    }

    .search-result__image {
        display: inherit;
    }
}
