/* Module: S */
.feature-blocks__content {
	max-width: pxToRem(450);
	padding-left: 90px;
	position: relative;
	z-index: 2;
	&.centered {
		padding-left: 0;
		max-width: pxToRem(410);
		margin: 0 auto;	
	}
}