/* Module: S */
.section__map-pannels {
	padding: 100px 0 50px 0;
	background-color: $mav__white;
}


.map-pannel {
	padding: 40px 106px 60px 106px;
	background-color: $vic__yellow;
	&:nth-child(odd) {
		margin-bottom: 10px;
	}

	&__img {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 200px;
		}
	}
	
	h3 {
		margin: 15px;
		font-family: Europa;
		font-size: pxToRem(38);
		font-weight: bold;
		color: #000;
		font-family: Europa;
		text-align: center;
	}

	p {
		font-size: pxToRem(16);
		color: #000;
		font-weight: normal;
		font-family: Europa;
		margin: 15px;
		text-align: center;
	}

	&__button {
		text-align: center;
	}

	.cta-btn.show-arrow {
		&:hover {
			color: #000;
		}
	}
}