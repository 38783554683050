// TOP NAV
$top-nav__bg: #f5f5f5;
$top-nav__item-bg: #0b3240;
$top-nav__color: #fff;
$top-nav__hover-bg: #feb739;
$top-nav__hover-color: #000;
$main-menu__bg: #fff;
$main-nav__color: $vic__border-grey;
$main-nav__color--hover: $color__primary;
$main-nav-top-bar__color: #feb739;
$header-search-input__border: $color__primary;
$header-search-button__color: $color__secondary;
$header-search-mobile__bg: #f0f2f3;
$header-search-focus__bg: #f4f4f4;

$menu-arrow__color: $color__primary;
$mega-menu__bg: #fff;
$mega-menu-border__color: $color__primary;
$mega-menu-link__color: #000000;
$mega-menu-sub-nav-title__color: #718dbd;
$mega-menu-sub-nav-link__color: #000000;
$mega-menu-sub-nav-link__color-hover: #0b3240;

$mobile-menu-lvl1__bg: $vic__grey;
$mobile-menu-lvl2__bg: $vic__dark-grey;
$mobile-menu-lvl3__bg: darken($mobile-menu-lvl2__bg, 6%);
