/* Module: S */
.lhs__menu {
    margin-right: $lhs-menu__margin-right + 2rem;
    @include sq-flex-shrink(0);
    @include sq-flex-basis($lhs-menu__width);

    .landing-image__container + .wrapper & {
        margin-top: -4rem;
    }
}

.lhs__menu-link {
    padding: 1rem 1.5rem;

    .menu__level-2 & {
        padding-left: 1.5rem;
    }

    .menu__level-3 & {
        padding-left: 2.75rem;
    }

    .menu__level-4 & {
        padding-left: 4rem;
    }

    .menu__level-5 & {
        padding-left: 5.25rem;
    }

    &.current.has-children {
        &::after {
            top: 1.15rem;
            right: 1.5rem;
        }
    }
}

.rhs__column {
    display: block;
    @include sq-flex-basis($rhs-column__width);
    margin-top: 0;
    margin-left: $rhs-column__margin-left;

    .is-listing + & {
        @include sq-flex-shrink(0);
        @include sq-order(inherit);
    }
}

.rhs__panel {
    width: 100%;
}

.rhs-panel__link {
    max-width: $rhs-column__width + 2rem;
}

.form-fieldset__content {
    display: inherit;
    margin-top: 0;
}

.addtocalendar {
    .atcb-list {
        left: auto;
        right: 0;
    }
}
.btt__wrapper {
    padding: 0 2rem;
}
