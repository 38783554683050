/* Module: S */
/* stylelint-disable */
.landscape-carousel__caption-wrapper {
    @include sq-flex-box;
    @include sq-justify-content(flex-start);
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
    position: absolute;
    // top: 50%;
    left: 50%;
    bottom: 0;
    z-index: 1;
    @include sq-transform(translate(-50%, 0));
}
/* stylelint-enable */

.landscape-carousel__controls {
    top: auto;
    bottom: 1rem;
    padding-left: 16rem;
    padding-right: 2rem;
}

.landscape-carousel__image {
    height: pxToRem(425);
}

.landscape-carousel__caption {
    // width: 100%;
    width: pxToRem(380);
    padding: 1.35rem 2rem;
    line-height: 1.35;
    background: none;

    &.is-video {
        // margin-bottom: 1.25rem;
        // &::after {
        //     padding-left: pxToRem(18);
        //     padding-right: pxToRem(18);
        // }
    }
}
.landscape-carousel__caption-bg {
    background: #000000;
    opacity: .5;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.landscape-carousel__caption-heading {
    // font-size: 185%;
    line-height: 1.28;
    margin-bottom: 1rem;
    padding-bottom: 0;
    border-bottom: 0;

    // &:after {
    //     content: '';
    //     width: 2.5rem;
    //     height: .35rem;
    //     background-color: $color__primary;
    //     display: block;
    //     margin-top: 1rem;
    // }
}

.landscape-carousel__caption-content {
    line-height: 1.65;
}

.landscape-carousel__item {
    background-color: transparent;
}
