/* Module: S */
.landing-panel {
    color: $landing-panel__color;
    background-color: $landing-panel__bg;
    border: $landing-panel__border;
    margin-top: $landing-panel__space-between;
    padding: 1rem;
    @include sq-transition(.2s all ease-in-out);

    &:first-child {
        margin-top: 0;
    }

    &:hover,
    &:focus {
        color: $landing-panel__color--hover;
        background-color: $landing-panel__bg--hover;
        border: $landing-panel__border--hover;
    }
}

.landing-panel__link {
    color: $landing-panel__color;
    text-decoration: none;
}

.landing-panel__heading {
    margin-top: 0;
    margin-bottom: .75rem;
}

.landing-panel__image {
    display: block;
    margin-bottom: 1rem;
}

.landing-image__container {
    box-shadow: inset 0px -100px 85px -50px rgba(0, 0, 0, 0.7);
}

.landing-image {
    position: relative;
    display: block;
    height: pxToRem(255);
    width: 100%;
    object-fit: cover;
    z-index: -1;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        z-index: inherit;
    }
}

.landing__content {
    h1,
    h2,
    h3 {
        margin-top: 0;
        margin-bottom: .5rem;
        line-height: pxToRem(44);
    }

    + .panels__container {
        margin-top: 1.5rem;
    }
}
