/* Module: S */
.feature-blocks {
	background-color: #fff;

	&__event, &__networks {
		padding: 50px 0 80px 0;
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	h2 {
		margin-bottom: 52px;
		color: #fff;
		font-size: pxToRem(38);
		line-height: pxToRem(44);
		font-weight: bold;
	}

	p {
		color: #fff;
		margin: 0;
		margin-bottom: 35px;
		font-size: pxToRem(16);
		line-height: pxToRem(24);
		font-weight: normal;
	}

	.white-bg {
		h2, p {
			color: #000;
		}

		.cta-btn:not(.yellow) {
			color: #000;
			border-color: #000;

			&:hover,
			&:focus {
				border-color: $mav__yellow;
			}

			use {
				svg {
					fill: #000;
				}
			}
		}

		.cta-btn {
			&.yellow {
				&:hover,
				&:focus {
					color: #000;
					use {
						svg {
							path {
								fill: #000;
							}
						}
					}
				}
			}
		}
	}
}

.feature-blocks__overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.feature-blocks__content {
	max-width: 100%;
	padding: 0 30px;
	position: relative;
	z-index: 2;
	&.centered {
		padding: 30px;
		max-width: 100%;
		margin: 0 auto;	
	}
}



.cta-btn {
	display: inline-block;
	border: solid 2px #fff;
    border-radius: 1.5625rem;
    background: none;
    font-size: pxToRem(16);
    font-weight: bold;
    padding: 0.625rem 2.25rem;
    color: #fff;
    margin-top: 1.5rem;
    text-decoration: none;
    margin: 0;
    margin-top: 15px;
    transition: background-color .5s, border .5s, color .5s;

    &:hover,
    &:focus {
    	background-color: $mav__yellow;
    	color: #000;
		border-color: $mav__yellow;

    	use {
    		svg {
    			path: #000;
    			transform: path .5s;
    		}
    	}
    }

    &.yellow {
    	background: $mav__yellow;
    	border-color: $mav__yellow;
    	color: #000;
		
		use {
			svg {
				path {
	    			fill: #000;
	    		}
			}
	    	
    	}

    	&:hover,
    	&:focus {
			background-color: transparent;
			color: #fff;

			use {
				svg {
					path {
						fill: #fff;
					}
				}
			}
    	}
    }

    span {
    	display: none;
    }

    &.show-arrow {
    	padding-right: 22px;
	    span {
	    	display: inline-block;
	    	line-height: pxToRem(16);
	    	padding-left: pxToRem(25);
	    	
	    	svg {
	    		width: pxToRem(28);
	    		height: pxToRem(19);
	    		position: relative;
	    		top: 3px;
	    	}
	    }	
    }    
}

