/* Module: S */
.member-services,
.landing__child-pages {
    @include sq-flex-box;
    @include sq-justify-content(space-between);
    @include sq-flex-wrap(wrap);
    max-width: $page__max-width;
    margin: 0 auto;
    padding: 2rem 1rem;
    &__headline {
        font-size: pxToRem(28);
        font-weight: bold;
        color: $mav__black;
        display: block;
        text-align: center;
        width: 100%;
        padding: 0 0 pxToRem(23);
    }
    .feature-box__container {
        margin-top: 0;
        margin-bottom: pxToRem(16);
        &:hover {
            .feature-box__heading {
                display: none;
            }
        }
    }
    .feature-box__heading {
        position: absolute;
        top: 0;
        width: 100%;
        background: none;
        font-size: 30px;
        color: $mav__white;
        height: auto;
        bottom: pxToRem(33);
        left: pxToRem(29);
        top: auto;
        width: 80%;
        text-decoration: underline;
        &:after {
            content: none;
        }
        &.text-dark {
            color: $mav__black;
        }
    }
    .feature-box__link {
        &:hover,
        &:focus {
            .feature-box__heading {
                background: none;
            }
        }
        &[rel="external"] {
            &:after {
                display: none;
            }
        }
    }
    .feature-box__content {
        display: none;
        background: none;
    }
}

.landing__child-pages {
    .no-img {
        background-size: 100% auto;
        background-position: center;
        &:nth-of-type(6n+1) {
            background-image: url('mysource_files/policy.png');
        }
        &:nth-of-type(6n+2) {
            background-image: url('mysource_files/mobile-organisation.png');
        }
        &:nth-of-type(6n+3) {
            background-image: url('mysource_files/mobile-governance.png');
        }
        &:nth-of-type(6n+4) {
            background-image: url('mysource_files/mobile-networks.png');
        }
        &:nth-of-type(6n+5) {
            background-image: url('mysource_files/mobile-organisation.png');
        }
        &:nth-of-type(6n) {
            background-image: url('mysource_files/policy.png');
        }
    }
    .feature-box__container {
        min-height: 165px;
    }
}

.member-services .feature-box_container,
.landingchild-pages .feature-box_container {
    margin-top: 0;
    margin-bottom: 0;
}

.section__map-pannels {
    padding: pxToRem(32) 0;
}

.member-services,
.landing__child-pages {
    padding: pxToRem(32) pxToRem(16) pxToRem(16);
}