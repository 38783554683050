/* Module: S */
.social-feed {
    background: $vic__purple;
    &__wrapper {
       
        padding: pxToRem(20) pxToRem(16);
    }
    &__heading {
        text-align: center;
        display: block;
        margin: 0 auto;
        color: $mav__white;
        padding: pxToRem(20) pxToRem(16);
    }
    &__item{
        width: 100%;
        display: block;
        background: transparent;
        padding: pxToRem(10);
        margin-bottom: pxToRem(10);
        max-height: 350px;
        overflow: hidden;
        text-align: center;
    }

    &__linkedin {
        & > img {
            display: block;
            margin: auto;
            margin-bottom: 10px;
        }

        .IN-widget {
            display: block !important;
            line-height: 0 !important;
            margin: 0 auto;
            max-width: 100%;

            & > span {
                display: block !important;
            }

            * {
                max-width: 100%;
                border: none;
                box-shadow: 0;
                background-color: #fff;
                display: block;
            }
        }
        &--content {
            text-align: left;
            color: $mav__black;
            max-width: 364px;
            margin: 0 auto;
            padding: 1px;
            padding-bottom: 0px;
            font-size: 14px;
            position: relative;
            top: -4px;
            z-index: 10;
            background: #ededed;
            p {
                margin: 0;
                background: #ededed;
                border: 1px solid #fff;
                border-top: none;
                border-radius: 5px;
                padding: 7px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                padding-top: 3px;
                position: relative;
                top: -1px;
                box-shadow: inset 0 30px 60px -65px #000;
                color: #777;
                font-size: 12px;
            } 
        }
    }
    &__facebook {
        & > img {
            max-height: 23px;
            display: block;
            margin: auto;
            margin-bottom: 10px;
        }

        .fb_iframe_widget_fluid {
            display: block;
            margin: 0 auto;
        }
    }
    &__twitter {
        & > img {
            max-height: 23px;
            display: block;
            margin: auto;
            margin-bottom: 10px;
        }

        iframe {
            max-width: 364px !important;
        }
    }
}