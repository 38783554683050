/* Module: S */
/* stylelint-disable */
.main__footer {
    font-size: 90%;
    color: $footer-link__color;
    background-color: $footer__bg;
    padding: 0 2rem 0 2rem;
    margin-top: 4rem;
}

body.home {
    .main__footer {
        margin-top: 0;
    }
}
/* stylelint-enable */

.footer-links__list,
.footer-social__list,
.footer-contact__list {
    @extend %list-reset;
}

.footer-links__item,
.footer-contact__item {
    font-size: pxToRem(15);
    margin-bottom: .75rem;
    font-weight: 300;

    &:last-child {
        margin-bottom: 0;
    }
}

/* stylelint-disable */
.footer-links__wrapper {
    width: 100%;
    max-width: 80rem;
}
/* stylelint-enable */

.footer-links__column {
    margin-bottom: 2.5rem;
    flex: 1;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &:first-child {
        flex: 3;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        content: '';
        display: inline-block;
        width: 3rem;
        height: 4px;
        margin-bottom: 2rem;
    }
}

.footer-link,
.footer-phone__link,
.footer-contact__heading {
    font-size: pxToRem(17);
    color: $footer-link__color;
    text-decoration: none;
    line-height: 1.5;
    display: inline-block;

    &:not(.footer-contact__heading):hover,
    &:not(.footer-contact__heading):focus {
        color: $footer-link__color--hover;
        text-decoration: underline;
    }
}

.footer-contact__heading {
    color: $footer-link__color;
    display: block;
    font-weight: inherit;
    margin-top: 0;
    margin-bottom: .5rem;
}

.footer-link.copy-right, .footer-contact__heading {
    font-weight: 600;
    // &:hover, &:focus {
    //     text-decoration: none;
    // }
}
.footer-link.copy-right, .footer-link.powered-site {
    &:hover, &:focus {
        text-decoration: none;
    }
}
.footer-link__text {
    color: $footer-link__text;
}



/* stylelint-disable */
.footer-social__wrapper {
    @include sq-flex-box;
    @include sq-justify-content(center);
    background-color: $footer-social___bg;
    margin-top: 3rem;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
/* stylelint-enable */

.footer-icon {
    fill: $mav__yellow;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    margin: 10px auto;
}

.footer-social__link {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .5rem;
    background-color: $footer-social-icons__bg;
    border-radius: .35rem;
    @include sq-transition(.1s background-color ease-in-out);

    .sr-only {
        color: #fcffff;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        background-color: #fff;
        outline: none;

        &.facebook {
            .footer-icon {
                fill: #3b5998;
            }
        }

        &.youtube {
            .footer-icon {
                fill: #bb0000;
            }
        }

        &.linkedin {
            .footer-icon {
                fill: #0077b5;
            }
        }

        &.instagram {
            .footer-icon {
                fill: #bc2a8d;
            }
        }

        &.twitter {
            .footer-icon {
                fill: #0084b4;
            }
        }
        &.vimeo {
            .footer-icon {
                fill: #1ab7ea;
            }
        }
    }
}

.footer-info {
    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-top: pxToRem(40);
        justify-content: space-between;
        flex-basis: 100%;
    }

    &__flags {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-right: pxToRem(2);
        }
    }
    &__text {
        font-size: pxToRem(15);
    }
}
