/* Module: S */
.planning-scheme__map {
    border: none;
    height: pxToRem(560);
    width:  100%;
}

.map__markers {
    background-color: transparent;
    color: #201547;
    cursor: pointer;
    display: block;
    // font-family: $sans-serif;
    font-size: pxToRem(16);
    font-weight: 600;
    height: 50px;
    line-height: 1.75;
    padding: pxToRem(5) pxToRem(25);
    position: relative;
    text-align: center;
    text-transform: capitalize;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    &::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        bottom: 0;
        content: '';
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

    &::after {
        background-color: #fff;
        content: '';
        display: block;
        height: 40px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}


.gm-style .gm-style-iw {
    overflow: visible;
    top: 0 !important;
    padding: 0px;
    > div {
        overflow: visible !important;
        > div {
            overflow: visible !important;
        }
    }
}
.map-popup {
    background: #fff;
    width: 300px;
    position: relative;
    padding-top: 40px;
    padding: 19px 5px 0;
    border-radius: 8px;

    &__paragraph, &__paragraph--number, &__btn-wrapper {
        margin: 0;

        span {
            color: #663367;
            font-weight: 600;
            margin-right: 20px;
        }
    }

    &__paragraph {
        margin-bottom: 10px;
    }

    &__content {
        padding: 10px;
        padding-left: 30px;
    }
    
    &__title {
        background: #663367;
        color: #fff;
        padding: 10px;
        width: 100%;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
    }

    &__contact-numbers {
        &:after {
            display: block;
            content: "";
            clear: both;
            float: none;
        }

        margin-bottom: 15px;
    }

    &__btn-wrapper {
        text-align: center;
    }

    &__btn {
        border-radius: 5px;
        background: $mav__yellow;
        color: $mav__black;
        padding: 10px 20px;
        display: inline-block;
        text-decoration: none;
        font-weight: 500;

        &:hover {
            color: $mav__white;
            background-color: $mav__blue--dark;
        }

        &:first-child {
            margin-right: 10px; 
        }
    }

    .width-50 {
        width: 50%;
        max-width: 50%;
        float: left;
    }
}