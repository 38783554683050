> h1:first-of-type {
    margin-top: 0;
    margin-bottom: .5em;
}
> h1:first-of-type {
  line-height: 1;
}
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color : $mav__heading-font;
  &:not(h1, .search-result__heading, .map__description-heading, .feature-box__heading, .toc__heading) {
    margin-bottom: .75rem;
    margin-top: 4rem;
  }
}

h1 {
    font-size: pxToRem(38);
}
h2 {
    font-size: pxToRem(28);
}
h3 {
    font-size: pxToRem(22);
}
h4 {
    font-size: pxToRem(18);
}
h5 {
    font-size: pxToRem(14);
}
h6 {
    font-size: pxToRem(12);
}
/* Tables */
/* stylelint-disable */
.responsive__table {
    width: 100%;
    overflow: auto;
    border-bottom: $table-row__border;
    margin: .75em 0;
}

table {
    width: 100%;
    min-width: 50rem;
    margin: 2px;

    ul,
    ol {
        // Unfortunately no other way to override unordered list mixin with &:not(...)
        > li {
            margin-bottom: .25rem !important;
        }
    }
}

tbody {
    tr {
        border-bottom: $table-row__border;
        @include sq-transition(.15s background-color linear);

        &:nth-child(odd) {
            background-color: $table-row-odd__bg;

            &:hover {
                background-color: $table-row-odd__bg--hover;
            }
        }

        &:nth-child(even) {
            background-color: $table-row-even__bg;

            &:hover {
                background-color: $table-row-even__bg--hover;
            }
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    font-size: pxToRem(14);
    line-height: 1.45;
    text-align: left;
    color: $table-heading__color;
    background-color: $mav__blue--dark;
    border-right: 1px solid #fff;
    padding: pxToRem(30) pxToRem(20) pxToRem(25);
    white-space: nowrap;
    font-weight: 400;

    &:last-child {
        border-right: 0;
    }
}

td {
    padding: 1em;

    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@include unordered-list($color__primary);
@include ordered-list(#2e2e2e);

/* Quotes */
blockquote {
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;
    margin: .5rem 0;
    background-color: $blockquote__background;
    color: $mav__blue--dark;
    font-size: pxToRem(16);
    line-height: 1.5;
    font-weight: 600;
    font-style: italic;

    &::before {
        content: '\201C';
        font-family: helvetica, arial, sans-serif;
        font-size: pxToRem(50);
        font-weight: 500;
        top: 0;
        left: 0;
        color: $mav__blue--dark;
        position: absolute;
        line-height: 1;
        height: 17px;
    }

    &::after {
        content: '\201D';
        font-family: helvetica, arial, sans-serif;
        font-size: pxToRem(50);
        font-weight: 500;
        bottom: 0;
        right: 0;
        color: $mav__blue--dark;
        position: absolute;
        line-height: 1;
        height: 17px;
    }    

    @-moz-document url-prefix() {
        &::before {
            top: 0;            
        }
    }
}

.pull-quote {
    border-top: 1px solid $base__font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}

/* Highlight */
.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}
/* stylelint-enable */
