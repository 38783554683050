/* Module: S */
.search-results__list {
    @extend %list-reset;
}

.search-result__item {
    margin-bottom: 2.5rem;
    border: 1px solid #f0f0f0;

    &:first-child {
        margin-top: 3rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .buttons-wrapper {
        text-align: right;
    }

    .btn {
        margin: 0 3px;
    }

    .listing-btn__more {
        background: $mav__yellow;
        color: $mav__black;

        &:hover {
            background-color: $mav__black;
            color: $mav__white;
        }
    }
    .listing-btn__register {
        background: $mav__blue--dark;
        color: $mav__white;
        &:hover {
            background: $mav__yellow;
            color: $mav__black;
        }
    }
}

ul > li.search-result__item {
    padding: 0;
}

.main__content ul.pagination > li::before {
    content: none;
}

.search-result__link {
    position: relative;
    color: $color__tertiary;
    text-decoration: none;
    background-color: #fff;
    @include sq-flex-box;
}

.search-result__desc {
    color: #58595b;
    margin-top: 1rem;
}

h2.search-result__heading {
    font-size: pxToRem(20);
    line-height: 1.4rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: .5rem;

    .search-result__best-bet & {
        color: $color__primary;
    }
}

.search-result__image-wrapper {
    display: none;
    position: absolute;
    height: 100%;
    width: 125px;
}

.search-result__image {
    display: none;
    max-width: 125px;
    height: 100%;
    width: 100%;
}

.search-result__content {
    padding: pxToRem(29);
    line-height: 1.5;
    @include sq-flex-shrink(1);
    width: 100%;

    .search-result__best-bet & {
        border-bottom: 5px solid $color__primary;
    }
}

.search-result__type {
    font-weight: 400;
    margin-top: 8px;
}

@media screen and (min-width: 35.3125em) {
    .search-result__image, .search-result__image-wrapper {
        display: inherit;
    }
    .search-result__image-wrapper + .search-result__content {
        padding-left: calc(125px + 29px);
    }
    .listing-3-col {
        .search-result__image, .search-result__image-wrapper {
            display: none;
        }
        .search-result__image-wrapper + .search-result__content {
            padding-left: pxToRem(29);
        }
    }

}

.search-result__date {
    font-size: pxToRem(14);
    margin-bottom: .5rem;
    time {
        font-weight: 400;
    }

    .search-result__content-inner & {
        margin-bottom: 0;
    }
}

.search-result__event-location {
    margin-bottom: .5rem;
}

.search-result__event-location {
    font-style: italic;
    font-weight: 400;
}

.search-result__url {
    line-height: 1rem;
    font-size: pxToRem(14);
    font-weight: 400;
    margin-top: 1rem;
    word-break: break-all;
}

.rhs-filters__heading {
    font-weight: normal;
    font-size: 1.35rem;
    line-height: 1.35rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
    padding-bottom: .45rem;
    border-bottom: 2px solid $vic__purple--bright;
}

.rhs-filters {
    @extend %clearfix;

    ul {
        @extend %list-reset;
    }

    &.form-filters {
        .rhs-filters__container {
            &[data-expanded='true'] {
                > .rhs-filters__heading::before {
                    top: pxToRem(5);
                    @include sq-transform(rotate(45deg));
                }
            }
        }

        .rhs-filters__heading {
            border-bottom: 1px solid #d8d8d8;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                right: 1.1rem;
                top: pxToRem(10);
                height: .75rem;
                width: .75rem;
                border-right: 4px solid $color__quaternary;
                border-bottom: 4px solid $color__quaternary;
                display: block;
                @include sq-transform(rotate(-135deg));
            }
            + form {
                display: none;
            }
        }
        legend {
            color: $color__quaternary;
            background-color: transparent;
            padding: 0 0 10px;
            // margin: 0 0 1rem 0;
            margin: 0;
            font-size: 125%;
            font-weight: 500;
            text-transform: none;
            width: auto;
            display: inline-block;
            cursor: default;
            &::before {
                content: none;
            }
            + .form-fieldset__content {
                display: block;
                margin-top: 1rem;
            }
            &::after {
                content: '';
                display: block;
                width: 1.25rem;
                height: 3px;
                margin-top: .25rem;
                background-color: $color__primary;
            }
        }
        fieldset.form__group {


            &[data-expanded='true'] {
                > legend::before {
                    @include sq-transform(none);
                }
            }
        }
    }
    &.facets-filters {

        legend {
            line-height: 2;
            border: 0;
            background-color: $color__tertiary;
            margin: 0;
            padding: .65rem 1rem;
            color: #fff;
            font-size: 80%;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                right: 1.1rem;
                top: 1.15rem;
                height: .75rem;
                width: .75rem;
                border-right: 4px solid #fff;
                border-bottom: 4px solid #fff;
                display: block;
                @include sq-transform(rotate(-135deg));
            }
        }

        fieldset.form__group {
            margin-bottom: 1rem;

            &[data-expanded='true'] {
                > legend::before {
                    top: .75rem;
                    @include sq-transform(rotate(45deg));
                }
            }
        }
    }
}

.calendar__icon {
    display: block;
    fill: $color__tertiary;
    height: 22px;
    width: 26px;
}

// DATEPICKER
.ui-datepicker {
    display: none;
    background-color: #fff;
    padding: .5rem;
    border: 1px solid rgba(0, 0, 0, .25);
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    margin-top: 3px;
}

.ui-datepicker-header {
    @include sq-flex-box;
    @include sq-justify-content(space-between);
    @include sq-align-items(center);
    margin-bottom: .4rem;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    > .ui-icon {
        display: none;
    }
}

/* stylelint-disable */
.ui-datepicker-prev,
.ui-datepicker-next {
    cursor: pointer;
    display: block;
    height: 28px;
    width: 28px;
    background-color: #eee;

    &::after {
        content: '';
        width: 0;
        height: 0;
        display: inline-block;
        border-style: solid;
        border-width: 7px 8px 7px 0;
        border-color: transparent #555 transparent transparent;
        vertical-align: middle;
        margin-top: 4px;
        margin-left: 9px;
    }

    &:hover,
    &:focus {
        background-color: darken(#eee, 6%);
    }
}
/* stylelint-enable */

.ui-datepicker-next {
    @include sq-order(3);

    &::after {
        border-width: 7px 0 7px 8px;
        border-color: transparent transparent transparent #555;
        margin-left: 10px;
    }
}

.ui-datepicker-calendar {
    width: 100%;
    min-width: pxToRem(200);

    td {
        padding: 1px;
    }

    thead {
        span {
            font-size: 80%;
            text-transform: uppercase;
            display: block;
            margin-bottom: .3rem;
        }
    }

    .ui-state-default {
        display: block;
        padding: .25rem .45rem;
        color: #555;
        font-size: pxToRem(15);
        font-weight: 400;
        text-align: center;
        text-decoration: none;
        background-color: #eee;

        &.ui-state-hover {
            background-color: darken(#eee, 6%);
        }

        &.ui-state-active {
            color: #fff;
            background-color: $color__secondary;
        }
    }
}

.datepicker__wrap {
    position: relative;

    .datepicker__clear {
        position: absolute;
        top: 50%;
        right: 2.75rem;
        padding: 0;
        border: 0;
        padding-bottom: 2px;
        height: auto;
        line-height: 18px;
        width: 1.25rem;
        color: #fff;
        background-color: $color__tertiary;
        display: none;
        border-radius: .2rem;
        @include sq-transition(.2s background-color ease-in-out);
        @include sq-transform(translateY(-50%));

        &:hover,
        &:focus {
            outline: 0;
            background-color: darken($color__secondary, 5%);
        }
    }
}
