/* Module: S */
.main__footer {
    @include sq-flex-box;
    @include sq-flex-direction;
    @include sq-flex-direction(column);
    font-size: 95%;
}

/* stylelint-disable */
.footer-links__wrapper {
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-flex-grow(1);
    @include sq-justify-content(space-between);
    margin-left: auto;
    margin-right: auto;
}
/* stylelint-enable */

.footer-links__column {
    width: auto;
    @include sq-flex-basis(auto);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.footer-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 10px auto;
}

.footer-social__link {
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 1rem;
}

// .footer-link,
// .footer-phone__link,
// .footer-contact__heading {
//     font-size: pxToRem(18);
// }

.footer-social__wrapper {
    margin-top: pxToRem(30);
}

.footer-info {
    &__wrapper {
        flex-direction: row;
        justify-content: space-between;
    }

    &__flags {
        display: flex;
        align-items: center;
        justify-content: left;
    }

    &__text {
        flex: 1;
        padding-left: pxToRem(124);
    }

}
