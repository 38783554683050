/* Module: S */
.landscape-carousel {
    display: none;

    &.slick-initialized {
        display: inherit;
    }
}

.landscape-carousel,
.landscape-carousel__caption-wrapper {
    position: relative;
    overflow: hidden;
}

.landscape-carousel__wrapper {
    position: relative;
}

.landscape-carousel__item {
    background-color: $mav__blue--dark;
}

.landscape-carousel__caption {
    color: $landscape-carousel-caption__color;
    text-decoration: none;
    background-color: $mav__blue--dark;
    display: block;
    padding: 1.75rem 1.25rem;
    position: relative;
    line-height: 1.25;

    &:hover,
    &:focus {
        color: $landscape-carousel-caption__color;
        .landscape-carousel__caption-heading::after {
            width: 100%;
        }
    }

    &.is-video {
        &::after {
            content: 'Watch Video';
            display: inline-block;
            font-size: pxToRem(15);
            font-weight: 600;
            color: #fff;
            border: 2px solid #fff;
            padding: pxToRem(10) pxToRem(36);
            margin-top: 1.5rem;
            border-radius: pxToRem(25);
            // position: absolute;
            // top: 15px;
            // right: 15px;
        }
    }
}

.landscape-carousel__image {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: pxToRem(200);
}

.landscape-carousel__caption-heading {
    font-size: pxToRem(28);
    font-weight: 600;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: .75rem;
    padding-bottom: .75rem;

    // &:after {
    //     width: 2.5rem;
    //     -moz-transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    //     -webkit-transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    //     transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    //     content: '';
    //     display: block;
    //     height: .25rem;
    //     margin-top: 1rem;
    //     background-color: $color__primary;
    // }
}

.landscape-carousel__caption-content {
    line-height: 1.75;
}

.landscape-carousel__caption-cta {
    border: solid 2px $landscape-carousel-caption__color;
    border-radius: pxToRem(25);
    background: none;
    font-size: pxToRem(15);
    padding: pxToRem(10) pxToRem(36);
    color: $landscape-carousel-caption__color;
    margin-top: pxToRem(24);
}

.landscape-carousel__dots {
    @include sq-flex-box;
    @include sq-flex-direction(row);
    @extend %list-reset;
    > li {
        margin-right: .75rem;

        button {
            display: block;
            height: pxToRem(14);
            width: pxToRem(14);
            border-radius: 50%;
            background-color: rgba(0, 0, 0, .15);
            border: pxToRem(2) solid #fff;
            margin: 4px 0;
            padding: 0;
            color: #757575;
            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
        }

        &.slick-active {
            button {
                background-color: #fff;
            }
        }
    }
}

/* stylelint-disable */
.landscape-carousel__controls {
    @include sq-flex-box;
    @include sq-justify-content(flex-start);
    @include sq-align-items(center);
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    top: 10.5rem;
    left: 50%;
    @include sq-transform(translateX(-50%));
}
/* stylelint-enable */

.landscape-carousel__toggler {
    display: block;
    background-color: transparent;
    height: pxToRem(14);
    width: pxToRem(12);
    border-width: 0 pxToRem(4) 0 pxToRem(4);
    border-style: none solid;
    border-color: transparent #fff;
    padding: 0;
    margin-left: .25rem;

    &.carousel-paused {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 0 7px 12px;
        border-color: transparent transparent transparent #fff;
    }
}
