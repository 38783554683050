/* Module: S */
.main-header__wrapper {
    margin: 0 auto;
    max-width: $page__max-width;
    padding-top: 14px;
    padding-bottom: 14px;
    @include sq-justify-content(space-between);

    &.dark {
        display: flex;
        padding: 0;
        max-width: 100%;
        justify-content: center;
    }
}

.header-search__wrapper {
    position: relative;
    padding-left: 0;
    margin-left: pxToRem(10);
    border-bottom: 1px solid #0b3240;
}

.header-search__input {
    position: relative;
    border: none;
    padding: pxToRem(10) pxToRem(35) pxToRem(10) 0;
    vertical-align: top;
    background: none;
    min-width: 357px;
}

.header-search__submit-button {
    float: none;
    position: absolute;
    top: auto;
    right: 3px;
    width: 38px;
    padding: 4px;
    @include ie-transparent();
    @include sq-transform(none);
}

.header-search__form {
    position: static;
    @include sq-flex-box();
}

.main-nav {
    display: block;
    width: 100%;
    max-width: 84rem;

    &__list {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.main-nav__link {
    color: $mav__white;
    text-decoration: none;
    font-weight: normal;
    font-size: pxToRem(18);
    padding: pxToRem(15) 1.25rem;
    padding-top: pxToRem(19);
    display: block;
    @include sq-transition(.2s color linear);

    &:hover,
    &:focus {
        color: $mav__white;
    }

    // &::after,
    // &::before {
    //     @include sq-transition(.3s ease opacity);
    // }

    // &::after {
    //     content: '';
    //     bottom: 0;
    //     display: block;
    //     width: 50px;
    //     height: 15px;
    //     background-color: $main-nav-top-bar__color;
    //     position: absolute;
    //     left: 50%;
    //     opacity: 0;
    //     @include sq-transform(translate(-50%, 0));
    // }

    // &::before {
    //     bottom: 0;
    //     content: '';
    //     position: absolute;
    //     left: 50%;
    //     display: block;
    //     height: .75rem;
    //     width: .75rem;
    //     border-right: 3px solid #fff;
    //     border-bottom: 3px solid #fff;
    //     opacity: 0;
    //     z-index: 2;
    //     @include sq-transform(translate(-50%, 0) rotate(-135deg));
    // }

    &.main-nav__link--active {
        // &::after {
        //     opacity: 1;
        // }

        &::before {
            // opacity: 1;
            background: $main-nav-top-bar__color;
            content: '';
            display: block;
            height: pxToRem(4);
            left: 0;
            top: 0;
            margin: 0 auto;
            position: absolute;
            width: 100%;
            will-change: transform;
            transition: transform .3s ease;
        }
    }
}

.menu-arrow {
    display: none;
}

.main-header__logo-title-wrapper {
    display: flex;
    align-items: center;

    .logo__text {
        display: block;
        margin: 0;
        margin-left: 52px;
        padding: 19px 0;
        padding-left: 32px;
        font-size: pxToRem(23);
        line-height: pxToRem(26);
        font-weight: bold;
        color: $vic__purple--bright;
    }
}

.mega-menu__wrapper {
    padding: pxToRem(36) 0;
    display: block;
    width: 100%;
    height: 400px;
    background: $mega-menu__bg;
    overflow: hidden;
}

.mega-menu__items {
    max-width: $page__max-width;
    margin: 0 auto;
    position: relative;
}
.mega-menu__close-button {
    background: none;
    border: none;
    padding: 0;
    display: block;
    height: 18px;
    width: 18px;
    position: absolute;
    right: 2rem;
    top: 0;
    .close-icon {
        display: block;
    }
}

.mega-menu__item {
    position: absolute;
    left: 50%;
    width: 100%;
    max-width: $page__max-width;
    padding-left: 2rem;
    padding-right: 3.5rem;
    opacity: 0;
    visibility: hidden;
    border-bottom: 0;
    @include sq-transform(translateX(-50%));
    @include sq-transition(.3s ease-in-out opacity, .3s ease-in-out visibility);

    &.mega-menu__item--visible {
        opacity: 1;
        visibility: visible;
    }
}

.mega-menu__feature {
    width: 30%;
    padding-right: 4rem;
    float: left;
}

.mega-menu__feature-title {
    margin-bottom: .75rem;
    color: $mega-menu-link__color;
    font-size: pxToRem(28);
}

.mega-menu__feature-description {
    display: block;
    line-height: 1.56;
    color: $mega-menu-sub-nav-link__color;
}

.mega-menu__feature-link {
    color: $vic__purple--bright;
    border: none;
    text-decoration: none;
    font-weight: bold;
    font-size: pxToRem(24);
    padding: 0;
    &:hover {
        text-decoration: underline;
        color: $vic__purple;
    }
}

.mega-menu__navs-wrapper {
    background: none;
    display: block;
    float: left;
    width: 70%;
    padding-top: 0;
    padding-bottom: 0;

    > .mega-menu__list {
        width: 33%;
        padding-right: 3rem;

        &:nth-child(3n) {
            padding-right: 0;
        }

        &:nth-child(n+4) {
            margin-top: 2rem;
        }
    }
}

.mega-menu__list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;

    .lvl-2 & {
        display: block;
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.mega-menu__sub-nav-title {
    margin-top: 0;
    margin-bottom: .5rem;

    .mega-menu__sub-nav-link,
    .mega-menu__sub-nav-text {
        font-size: pxToRem(24);
        color: $vic__purple--bright;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
    font-size: pxToRem(18);
    color: $mega-menu-sub-nav-link__color;
    display: inline-block;
    font-weight: inherit;
    padding: .2rem 0;
    text-decoration: none;
    @include sq-transition(.1s color ease-in-out);

    &:hover,
    &:focus {
        color: $vic__purple;
        text-decoration: underline;
    }
}

.mega-menu__sub-nav-text {
    font-weight: 600;
    &:hover {
        text-decoration: none;
    }
}

.main-menu__btn,
.header-search__mobile-btn {
    display: none;
}
